.title {
  text-align: center;
}

#page-container {
  position: relative;
  min-height: 97.5vh;
}

#content-wrap {
  padding-bottom: 2.5rem;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  text-align: center;
}